import React, { useState, useEffect } from 'react';
import axios from "axios";
import welcome from './success.png';
import fail from './fail.jpg';

const LoggedInComponent = ({ loginSuccess, userEmail, onLogout }) => {
    useEffect(() => {
        console.log('Login Success:', loginSuccess);
    }, [loginSuccess]);

    return (
        <div key={loginSuccess} style={{
            textAlign: 'center',
            color: loginSuccess ? 'green' : 'red',
            border: loginSuccess ? '2px solid green' : '2px solid red',
            width: '300px',
            margin: '50px auto', // Center both horizontally and vertically
            padding: '20px'
        }}>
            {loginSuccess ? (
                <>
                    <h1 style={{fontSize: '1.5em'}}><img src={welcome} alt="Welcome" style={{width: '10%', height: '20px'}}/> Login successful!</h1>
                    <h2 style={{color: 'black'}}>Now you can use the Server Control Panel</h2>
                    <p>User Email: {userEmail}</p>
                    <button onClick={onLogout}>Logout</button>
                </>
            ) : (
                <>
                    <img src={fail} alt="Failed" style={{width: '80%', height: 'auto' }} />
                    <h1>Login Failed!</h1>
                    <h2 style={{ color: 'red' }}>Please check your credentials and try again.</h2>
                </>
            )}
        </div>
    );
};

const Login = ({ onCredentialsChange }) => {
    const [email, setEmail] = useState('');
    const [pw, setPw] = useState('');
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [failureMessage, setFailureMessage] = useState('');
    const [logout, setLogout] = useState(false);

    const handleEmailChange = ({ target: { value } }) => {
        setEmail(value);
        onCredentialsChange({ email: value, pw });
    };

    const handlePasswordChange = ({ target: { value } }) => {
        setPw(value);
        onCredentialsChange({ email, pw: value });
    };

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('https://api.fnsbsa.com/api-challenge/v3/login', {
                email,
                pw
            });

            const { rtCode, data } = response.data;

            if (rtCode === 0) {
                setLoginSuccess(true);
                console.log('로그인 성공, 토큰:', data);
                onCredentialsChange({ email, pw, token: data });

                // Pass the user's email to the LoggedInComponent
                setUserEmail(email);
            } else {
                setLoginSuccess(false);
                console.error('로그인 실패:', rtCode);
            }
        } catch (error) {
            setLoginSuccess(false);
            console.error('API 요청 실패:', error);
        }
    };

    const handleLogout = () => {
        setLoginSuccess(false);
        setUserEmail('');
        setLogout(true);
    };

    useEffect(() => {
        if (logout) {
            console.log('User logged out');
            setLogout(false); // Reset the logout state
        }
    }, [logout]);

    return (
        <div>
            {loginSuccess ? (
                <LoggedInComponent
                    key={loginSuccess ? 'success' : 'failure'}
                    loginSuccess={loginSuccess}
                    userEmail={userEmail}
                    onLogout={handleLogout}
                />
            ) : (
                <form className="login-container" onSubmit={handleLogin}>
                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input type="text" id="email" value={email} onChange={handleEmailChange} autoComplete="username" />
                    </div>
                    <div className="input-group">
                        <label htmlFor="pw">Password</label>
                        <input type="password" id="pw" value={pw} onChange={handlePasswordChange} autoComplete="current-password" />
                    </div>
                    <button type="submit">Login</button>
                </form>
            )}
        </div>
    );
};

export default Login;
