// HomePage.js
import React, {useState} from 'react';
import Login from './Login';
import ServerControl from './ServerControl';

const titleStyle = {
    textAlign: 'center', // 가운데 정렬
    marginTop: '20px' // 상단 간격 조절
};

const HomePage = () => {
    const [credentials, setCredentials] = useState({ email: '', pw: '', token: '' }); // Add token to the state

    const handleCredentialsChange = (newCredentials) => {
        // Update the credentials state
        setCredentials(newCredentials);
    };

    return (
        <div>
            <h1 style={titleStyle}>Application Challenge Server Console</h1>
            <Login onCredentialsChange={handleCredentialsChange} />
            <ServerControl email={credentials.email} pw={credentials.pw} token={credentials.token} />
        </div>
    );
}

export default HomePage;