import React, { useState } from 'react';
import axios from 'axios';

const ServerControl = ({ email, pw, token }) => {
    const [buttonStates, setButtonStates] = useState({
        Tomcat: {
            Start: false,
            Stop: false,
            Restart: false,
        },
        Nginx: {
            Start: false,
            Stop: false,
            Restart: false,
        },
    });

    const [rtMsg, setRtMsg] = useState('');

    const handleServerButtonClick = async (action, server) => {

        const serviceType = server === 'Tomcat' ? 'CMMSVT001' : 'CMMSVT002';
        const changeStatus = action.toUpperCase();

        if (!email || !pw) {
            setRtMsg('Please check email and password.');
            return;
        }

        try {
            // Send POST request to the specified URL with the payload
            const response = await axios.put(
                'https://api.fnsbsa.com/api-challenge/v3/service/control',
                {
                    email: email,
                    pw: pw,
                    serviceType,
                    changeStatus
                },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            if (response.data.rtCode === 0) {
                // API response code is 0, handle success
                setRtMsg(response.data.rtMsg);
            } else if (response.data.rtCode === 8002) {
                // Wrong email or password
                setRtMsg('Wrong email or password')
            } else {
                // API response code is not 0, handle error
                setRtMsg(`Error: ${response.data.rtCode}`);
            }

            // Reset the state of other buttons
            setButtonStates(prevStates => ({
                Tomcat: {
                    Start: server === 'Tomcat' && action === 'Start',
                    Stop: server === 'Tomcat' && action === 'Stop',
                    Restart: server === 'Tomcat' && action === 'Restart',
                },
                Nginx: {
                    Start: server === 'Nginx' && action === 'Start',
                    Stop: server === 'Nginx' && action === 'Stop',
                    Restart: server === 'Nginx' && action === 'Restart',
                },
            }));
        } catch (error) {
            // Handle errors, log them, or update your UI accordingly
            setRtMsg(`Server error`)
            console.error('Error sending request:', error);
        }
    };

    const resetRtMsg = () => {
        setRtMsg('');
    };

    const getButtonStyle = (server, action) => {
        const isActive = buttonStates[server][action];
        return {
            padding: '8px 16px',
            fontSize: '1rem',
            margin: '4px',
            width: 100,
            backgroundColor: isActive ? '#3498db' : '#4CAF50',
            color: '#fff',
        };
    };

    const containerStyle = {
        textAlign: 'center',
        marginTop: '20px',
    };

    return (
        <div style={containerStyle}>
            <h2>Server Control Panel</h2>

            {/* Tomcat Server Controls */}
            <h3>Tomcat Server</h3>
            <button
                style={getButtonStyle('Tomcat', 'Start')}
                onClick={() => {
                    resetRtMsg(); // Reset rtMsg before handling the button click
                    handleServerButtonClick('Start', 'Tomcat');
                }}
            >
                Start
            </button>
            <button
                style={getButtonStyle('Tomcat', 'Stop')}
                onClick={() => {
                    resetRtMsg(); // Reset rtMsg before handling the button click
                    handleServerButtonClick('Stop', 'Tomcat');
                }}
            >
                Stop
            </button>
            <button
                style={getButtonStyle('Tomcat', 'Restart')}
                onClick={() => {
                    resetRtMsg();
                    handleServerButtonClick('Restart', 'Tomcat');
                }}
            >
                Restart
            </button>

            {/* Nginx Server Controls */}
            <h3>Nginx Server</h3>
            <button
                style={getButtonStyle('Nginx', 'Start')}
                onClick={() => {
                    resetRtMsg();
                    handleServerButtonClick('Start', 'Nginx');
                }}
            >
                Start
            </button>
            <button
                style={getButtonStyle('Nginx', 'Stop')}
                onClick={() => {
                    resetRtMsg();
                    handleServerButtonClick('Stop', 'Nginx');
                }}
            >
                Stop
            </button>
            <button
                style={getButtonStyle('Nginx', 'Restart')}
                onClick={() => {
                    resetRtMsg();
                    handleServerButtonClick('Restart', 'Nginx')
                }}
            >
                Restart
            </button>
            <p></p>
            {rtMsg && <div style={{color: "red"}}>{rtMsg}</div>}
        </div>
    );
};

export default ServerControl;
